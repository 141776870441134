<template>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" class="float-right mr-5"
          >mdi-share-variant-outline</v-icon
        >
      </template>

      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("share.shared with") }} :</span>
        </v-card-title>
        <v-form ref="form">
          <v-card-text>
            <v-container>
              <v-row v-for="(item, index) in share" :key="item.id">
                <v-col class="pa-2" cols="12" sm="6"> {{ item.name }} </v-col>
                <v-col class="pa-2" cols="12" sm="5">
                  {{ $t("access." + item.access) }}
                </v-col>
                <v-icon class="" small @click="removeShare(item.id, index)"
                  >mdi-delete</v-icon
                >
              </v-row>
              <v-row v-if="added">
                <v-col class="pa-2 mr-5" cols="12" sm="5">
                  <v-select
                    v-model="form.user_id"
                    :items="users"
                    item-text="name"
                    item-value="id"
                    :label="$t('share.users')"
                  ></v-select>
                </v-col>
                <v-col class="pa-2 ml-5" cols="12" sm="5">
                  <v-select
                    v-model="form.access"
                    :items="access"
                    item-text="text"
                    item-value="id"
                    :label="$t('share.access')"
                  ></v-select
                ></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-icon
                    v-if="!added"
                    @click="added = true"
                    class="float-right mt-5 mr-5"
                    >mdi-account-plus</v-icon
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="gray" @click="closeDialog">{{ $t("Close") }}</v-btn>
            <v-btn v-if="added" color="primary" @click="saveShare(0)">{{
              $t("Save")
            }}</v-btn>
          </v-card-actions>
        </v-form>
        <v-progress-linear
          v-if="sending"
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
        <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
          $t(errorMessage)
        }}</v-alert>
      </v-card>
    </v-dialog>
</template>
<script>
import crudMixin from "mixins/crudForm";
export default {
  mixins: [crudMixin],
  props: ["type"],
  mounted() {
    this.form.model_id = this.$route.params.id;
    this.form.model_type = this.type;
    this.getList();
  },
  data() {
    return {
      form: {},

      access: [
        { id: "read", text: this.$t("access.read") },
        { id: "copy", text: this.$t("access.copy") },
        { id: "write", text: this.$t("access.write") },
      ],
      added: false,
      errorMessage: "",
      users: [],
      shares: [],
      share: [],
      dialog: false,
    };
  },
  methods: {
    getList() {
      this.users = [];
      this.shares = [];
      this.share = [];
      this.get("/get-users")
        .then((data) => {
          this.users = data;
        })
        .catch((error) => {
          this.alert(error.message);
        });
      this.get("/model/shares", this.form)
        .then((data) => {
          this.shares = data.shares;

          setTimeout(() => {
            this.sort();
          }, 500);
        })
        .catch((error) => {
          this.alert(error.message);
        });
    },
    closeDialog() {
      this.dialog = false;
      this.added = false;
    },
    removeShare(item, index) {
      this.saveShare(item, index);
    },
    saveShare(id, index) {
      if (id != 0) {
        this.form.user_id = id;
        this.form.access = "none";
      }
      this.form.model_id = this.$route.params.id;
      this.form.model_type = this.type;
      this.post("/model/share", this.form)
        .then(() => {
          this.errorMessage = "";
          if (id != 0) {
            this.share.splice(index, 1);
          } else {
            this.appendToList(this.form.user_id, this.form.access);
          }

          this.form.access = "";
          this.form.user_id = "";
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
      this.added = false;
    },
    appendToList(id, access) {
      let name = "";
      this.users.forEach((element) => {
        if (element.id == id) name = element.name;
      });
      let modified = false;
      this.share.forEach((element) => {
        if (element.id == id) {
          element.access = access;
          modified = true;
        }
      });
      if (!modified) {
        this.share.push({
          id: id,
          name: name,
          access: access,
        });
      }
    },

    sort() {
      for (const [key] of Object.entries(this.shares)) {
        for (const item of Object.entries(this.users)) {
          if (item[1].id == key) {
            this.share.push({
              id: item[1].id,
              name: item[1].name,
              access: this.shares[key],
            });
          }
        }
      }
    },
  },
};
</script>
