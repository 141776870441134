<template>
  <v-container fluid tag="section">
    <v-row class="mt-4">
      <v-expansion-panels class="ml-3 mr-3 mt-5" multiple v-model="newQuiz">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="subtitle-1 font-weight-light">
              {{ $t("group.group details") }}
            </div>

            <div class="subtitle-3 font-weight-light">
              {{ $t("group.group subtitle") }}
            </div>
            <share-dialog
              v-bind:type="type"
              v-if="this.General.can_copy"
            ></share-dialog>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="ml-3 mr-3">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="submit"
            >
              <div class="work-experiences">
                <div class="form-row">
                  <div class="form-group">
                    <v-text-field
                      :label="$t('group.name')"
                      name="name"
                      v-model="General.name"
                      :rules="rules.name"
                      required
                    />

                    <v-textarea
                      :label="$t('group.description')"
                      name="remarks"
                      v-model="General.description"
                      :rules="rules.name"
                      required
                    ></v-textarea>
                  </div>
                </div>
                <v-col cols="12" class="mb-5">
                  <v-btn
                    v-if="!success"
                    color="primary"
                    class="mr-2 float-right"
                    type="submit"
                  >
                    {{ $t("group.save group") }}
                  </v-btn>
                </v-col>
                <v-alert type="success" class="mt-3" v-if="success">
                  {{ $t("quiz.saved") }}
                </v-alert>
              </div>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <participants-table v-if="gid" v-bind:gid="gid"></participants-table>
    </v-row>
  </v-container>
</template>

<script>
import ParticipantsTable from "@/pages/clients/ParticipantsTable";
import ShareDialog from "@/pages/quizzes/ShareDialog";
//import validate from "@/plugins/validate";
export default {
  components: {
    ParticipantsTable,
    ShareDialog,
  },

  data() {
    return {
      type: "App\\Models\\TargetGroup",
      apiQuery: "/target-group/",
      valid: false,
      newQuiz: [],
      success: false,
      General: {},
      Quiz: [],
      Questions: [],
      Answers: [],
      gid: "",
      status: ["draft", "published", "archived"],
      rules: {
        name: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("quiz.field"),
            }),
        ],
      },
    };
  },

  mounted() {
    if (this.$route.params.id && this.$route.params.id != "create") {
      this.gid = this.$route.params.id;
      this.get(this.apiQuery + this.gid)
        .then((data) => {
          this.General = data;
        })
        .catch((error) => {
          this.alert(error.message);
        });
    } else {
      this.newQuiz.push(0);
    }
  },
  methods: {
    submit() {
      if (!this.gid) {
        this.post("/target-group", this.General)
          .then((res) => {
            this.gid = res.id;
            this.$router.push("/clients/groups/");
          })
          .catch((error) => {
            this.alert(error.message);
          });
      } else {
        this.patch("/target-group/" + this.gid, this.General).catch((error) => {
          this.alert(error.message);
        });
      }
    },
  },
};
</script>
